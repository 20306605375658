<template>
  <div>
    <div v-if="!loading" class="flex gap-6 flex-col">
      <Accordion header="Información básica del informe">
        <div class="px-5 pb-8 flex flex-wrap gap-2 w-full flex-col">
          <div class="w-2/3">
            <h2 class="text-left text-md text-blue-dark font-bold capitalize">
              <span class="text-gray-800">Entidad: </span> {{ sfc_name }}
            </h2>
          </div>
          <h4 class="text-left text-md font-bold">Descripción</h4>
          <p class="text-justify text-md bg-gray-50 rounded-xl p-4">
            {{ description }}
          </p>
        </div></Accordion
      >
      <Step1 lectureMode />
      <Step2 lectureMode />
      <Step3 lectureMode />
      <Step4 lectureMode />
      <Step5 lectureMode />
      <Accordion header="Gestión por parte de la entidad">
        <div class="relative">
          <h2 class="mb-3 text-2xl text-left text-blue-dark">
            <b>Informe {{ sfc_name }}</b>
          </h2>
        </div>
        <div class="flex flex-col gap-1">
          <p class="text-gray-500 font-bold text-left">
            Decisiones a favor del consumidor aceptadas por la entidad
          </p>
          <div class="w-1/2">
            <BaseInput
              label="Número"
              v-model="reportManagement.numberAccepted"
              :disabled="true"
            />
          </div>
          <BaseTextarea
            label="Descripción consolidada"
            rows="3"
            v-model="reportManagement.descriptionAccepted"
            :disabled="true"
          />
          <div class="w-11/12 grid grid-cols-1 gap-2">
            <BaseDownload
              v-for="(option, index) in reportManagement.annexesAccepted"
              :value="option.name_file"
              :key="index"
              :url_download="option.url_file"
            />
          </div>

          <p class="text-gray-500 font-bold text-left mt-5">
            Decisiones a favor del consumidor no aceptadas
          </p>
          <div class="w-1/2">
            <BaseInput
              label="Número"
              v-model="reportManagement.numberRejected"
              :disabled="true"
            />
          </div>
          <BaseTextarea
            label="Descripción consolidada"
            rows="3"
            maxlength="2000"
            v-model="reportManagement.descriptionRejected"
            :disabled="true"
          />
          <p class="text-left text-gray-500 font-bold text-md mt-3">
            Archivos adjuntos
          </p>
          <div class="w-11/12 grid grid-cols-1 gap-2">
            <BaseDownload
              v-for="(option, index) in reportManagement.annexesRejected"
              :value="option.name_file"
              :key="index"
              :url_download="option.url_file"
            />
          </div>
        </div>
      </Accordion>
    </div>
    <div
      v-else
      class="spinner absolute w-full h-full top-0 flex items-center justify-center"
    >
      <a-spin class="">
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
  </div>
</template>
<script>
import Step1 from "@/components/Dashboard/FCO/Reports/FormSteps/Step1.vue";
import Step2 from "@/components/Dashboard/FCO/Reports/FormSteps/Step2.vue";
import Step3 from "@/components/Dashboard/FCO/Reports/FormSteps/Step3.vue";
import Step4 from "@/components/Dashboard/FCO/Reports/FormSteps/Step4.vue";
import Step5 from "@/components/Dashboard/FCO/Reports/FormSteps/Step5.vue";
import ReportForm from "@/components/Dashboard/FCO/Reports/ReportForm.vue";
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
export default {
  components: { ReportForm, Step1, Step2, Step3, Step4, Step5, Accordion },
  data() {
    return {
      loading: false,
      sfc_name: "",
      description: "",
      reportManagement: {
        numberAccepted: "",
        descriptionAccepted: "",
        annexes: [],
        numberRejected: "",
        descriptionRejected: "",
      },
    };
  },
  created() {
    this.loading = true;
    setTimeout(() => (this.loading = false), 2500);
    this.getStep0();
    this.getStep6();
  },
  methods: {
    async getStep0() {
      let { error, data } = await this.$api.getStepReport(
        this.$route.query.annual_report,
        0
      );
      if (data) {
        this.sfc_name = data.data.general.company.name_company;
        this.description = data.data.general.description;
      }
      if (error) {
        this.showToast(
          "error",
          "Ocurrió un error obteniendo la información básica del informe."
        );
      }
    },
    async getStep6() {
      let { error, data } = await this.$api.getStepReport(
        this.$route.query.annual_report,
        6
      );
      if (data) {
        this.reportManagement.numberAccepted =
          data.data.company.decisions_accepted_by_company;
        this.reportManagement.descriptionAccepted =
          data.data.company.description_accepted;
        this.reportManagement.numberRejected =
          data.data.company.decisions_rejected_by_company;
        this.reportManagement.descriptionRejected =
          data.data.company.description_rejected;
        this.reportManagement.annexesAccepted = data.data.annexes.filter(
          (item) => item.reference === 23
        );
        this.reportManagement.annexesRejected = data.data.annexes.filter(
          (item) => item.reference === 24
        );
      }
      if (error) {
        this.showToast(
          "error",
          "Ocurrió un error obteniendo la información sobre gestión del informe."
        );
      }
    },
  },
  computed: {
    optionsCompanies: function () {
      return this.$store.state.form?.optionsCompanies?.filter(
        (item) => item?.direction == this.$store.state?.session?.user.direction
      );
    },
  },
};
</script>