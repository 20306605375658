<template>
    <CompanyReportDetail/>
</template>
<script>
    import CompanyReportDetail from '@/components/Dashboard/FCO/Reports/CompanyReportDetail.vue';
    export default {
        components:{
            CompanyReportDetail,
        }
    }
</script>